<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <v-btn
        v-if="index > -1"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-btn
        slot="star-area"
        icon
        @click.native="form.isStar = !form.isStar"
      >
        <v-icon
          v-if="!form.isStar"
          color="grey lighten-1"
        >
          mdi-star-outline
        </v-icon>
        <v-icon
          v-else
          color="yellow"
        >
          mdi-star
        </v-icon>
      </v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <v-card-text
        slot="content"
      >
        <v-container fluid class="pb-0">
          <v-form ref="form">
            <v-row
              align="center"
              justify="center"
              no-gutters
            >
              <v-col cols="12" xs="12">
                <v-row
                  no-gutters
                  align="center"
                  justify="center"
                  class="pt-2"
                >
                  <v-col cols="6">
                    <v-select
                      v-model="form.gubun1"
                      :items="vals"
                      label="발령구분"
                      dense
                      menu-props="auto"
                    ></v-select>
                  </v-col>
                  <v-col cols="6" class="text-left">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          small
                          v-on="on"
                          class="ml-3 pb-1"
                        >mdi-help-circle-outline</v-icon>
                      </template>
                      <span>발령구분의 추가, 변경, 삭제는 [관리자>카테고리관리]에서 가능합니다.</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12">
                <v-menu
                  ref="date1Selector"
                  v-model="date1Selector"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model.trim="form.date1"
                      v-validate="'required|max:10'"
                      maxlength="10"
                      :error-messages="errors.collect('date1')"
                      data-vv-name="date1"
                      required
                      label="발령일"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="form.date1"
                    locale="ko"
                    @change="saveDate1"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" xs="12" sm="4">
                <v-text-field
                  v-model.trim="form.name"
                  v-validate="'required|max:20'"
                  maxlength="20"
                  :error-messages="errors.collect('name')"
                  data-vv-name="name"
                  required
                  label="대상자"
                  class="mr-2 pa-0"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="8">
                <v-text-field
                  v-model.trim="form.d1"
                  class="pa-0"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" class="ma-0 pa-0">
                <v-textarea
                  ref="txtaDta1"
                  v-model="form.dta1"
                  outlined
                  label="내용"
                  placeholder="입력하세요"
                  no-resize
                  rows="5"
                  counter="100"
                  :rules="rules"
                  @update:error="chkValidate = false"
                ></v-textarea>
              </v-col>
              <v-col cols="12" xs="12">
                <v-alert
                  border="left"
                  outlined
                  type="info"
                  dense
                  class="text-body-2"
                >
                  {{ staffInfo.name }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        small color="primary lighten-2"
                        @click="gotoDetail"
                      >mdi-launch</v-icon>
                    </template>
                    <span>바로가기</span>
                  </v-tooltip>
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-container>

      </v-card-text>

    </baseModal>

  </v-dialog>
</template>

<script>
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그
import ko from 'vee-validate/dist/locale/ko'
import strDateFormat from '@/filters/strDateFormat'

export default {
  components: {
    baseModal
  },

  props: [
    'staffInfo',
    'bal'
  ],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      from: '', // 중요: 안쓰는게 좋지만 여기가 최적이라.
      color: 'primary',
      width: 500,
      zIndex: 200
    },
    // 참고: for Form
    form: {
      id: 0,
      email: '',
      name: '',
      type1: 1, // 타입1 - 발령내역
      isStar: 0, // 중요표시 : 별
      gubun1: '입사',
      date1: '',
      d1: '',
      dta1: ''
    },
    viewWname: '', // 참고: 처리자를 보여주기만 하는 변수
    viewUpdatedAt: '', // 참고: 업데이트 일자를 보여주기 위한 변수
    vals: [], // !! 발령구분 카테고리
    dictionary: {
      messages: ko.messages,
      attributes: {
        name: '대상자'
      }
    },
    // 중요: 텍스트박스의 글자수 체크 변수 - rules[0]() 함수는 true 나 문자열을 반환한다.
    rules: [ v => v.length <= 100 || '100자 이하의 내용을 입력해 주세요' ],
    chkValidate: true, // 폼 유효성 - 기타 셀렉트 100자 이상이면 false
    date1Selector: false, // 참고: 발령일 셀렉터 메뉴
    index: -1 // 수정 인덱스
  }),

  created () {
    // 발령일 초기값은 오늘
    this.form.date1 = this.$moment().format('YYYY-MM-DD')
  },

  mounted () {
    this.$validator.localize('ko', this.dictionary)

    // 카테고리 : 발령구분 = 16
    this.setCates('16').then(({ cates }) => {
      if (cates && cates.length > 0) this.vals = cates.map(c => c.gubun1)
      else this.vals = []
    })
  },

  methods: {
    dummy () {
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화 함수
    initDialog () {
      // form validation 초기화
      this.$validator.reset()

      // 참고: staffInfo 변수중 이메일만 받는다
      this.form.email = this.staffInfo.email

      this.form.type1 = 1 // 참고: 타입1 = 1 : 발령내역을 의미한다

      this.form.isStar = 0 // 중요표시 - 별
      this.form.gubun1 = '입사' // 구분
      this.form.date1 = this.$moment().format('YYYY-MM-DD') // 발령일 초기값은 오늘
      this.form.dta1 = '' // 내용

      // 참고: 이름과 직위|자격은 staffInfo 에서 초기값을 가져온다.
      this.form.name = this.staffInfo.name
      this.form.d1 = (this.staffInfo.rank && this.staffInfo.position)
        ? `${this.staffInfo.rank}|${this.staffInfo.position}`
        : this.staffInfo.rank || this.staffInfo.position

      this.index = -1 // 참고: 수정용 인덱스 초기화
    },
    //
    open (title, options, index = -1, obj = {}) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        this.initDialog()

        // 참고: 편집 모드인 경우 - 넘어온 객체를 this.form 에 복사
        if (index > -1) {
          this.index = index

          // this.form = Object.assign(this.form, obj)
          // 참고: 사용하는 변수만 폼값에 매칭하자!
          this.form.id = obj.id
          this.form.name = obj.name
          this.form.type1 = obj.type1
          this.form.isStar = obj.isStar
          this.form.gubun1 = obj.gubun1
          this.form.date1 = obj.date1
          this.form.d1 = obj.d1
          this.form.dta1 = obj.dta1

          // 참고: 편집일때는 처리자와 업데이트 일자를 보여준다.
          // 발령,휴가|근태,인사평가만
          this.viewWname = obj.wname
          this.viewUpdatedAt = strDateFormat(obj.updatedAt) // 필터로 쓰진 않고 함수로 사용
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.ddㅁㅁ
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 등록 버튼
    async agree () {
      try {
        if (!this.form.email) throw new Error('정상적인 데이터가 아닙니다.')
        // console.log(this.form)

        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // 중요: textarea 의 글자수가 100 이하로 조정되면 여기서 체크해서 처리한다.
        if (this.rules[0](this.$refs.txtaDta1.value) === true) {
          this.chkValidate = true
        }
        if (!this.chkValidate) throw new Error('저장할 수 없습니다. 내용 항목의 글자수를 줄여주세요.')

        let result = null
        if (this.index > -1) {
          // 참고: 편집 모드인 경우
          const { data } = await this.$axios.post('admin/staff/editStaffCommonById', this.form)
          if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
          result = data.resObj
        } else {
          // 참고: 등록 모드인 경우
          const { data } = await this.$axios.post('admin/staff/addStaffCommon', this.form)
          if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
          result = data.resObj
        }

        if (this.options.from === 'list') {
          // 상세 페이지가 아닌 리스트에서 열린 경우
        } else {
          // 상세페이지에서 열린 경우
          // 참고: 등록/수정/삭제가 성공하면 백엔드에서 리스트를 다시 패칭해온다 해당 데이터로 부모창의 데이터를 변경한다.
          // 주의: this.bal = data.bal 로 하면 에러!
          this.bal.totalItems = result.totalItems
          this.bal.list = result.list
        }

        this.initDialog()

        this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소 버튼
    cancel () {
      // 다이얼로그를 닫으면서 초기화 해야 한다!
      this.initDialog()
      this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
      this.dialog = false
    },
    // 참고: 삭제 버튼 - 수정모드에서만 발동됨
    async remove () {
      try {
        // !! 수정 모드가 아니면 에러
        if (!(this.index || this.index > -1)) throw new Error('삭제할 수 없습니다.')

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `삭제 하시겠습니까? 삭제된 데이터는 복구할 수 없습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 370 })) {
          let result = null
          const { data } = await this.$axios.post('admin/staff/deleteStaffCommonById', this.form)
          if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
          result = data.resObj

          if (this.options.from === 'list') {
            // 상세 페이지가 아닌 리스트에서 열린 경우
          } else {
            // 상세페이지에서 열린 경우
            // 참고: 등록/수정/삭제가 성공하면 백엔드에서 리스트를 다시 패칭해온다 해당 데이터로 부모창의 데이터를 변경한다.
            // 주의: this.bal = data.bal 로 하면 에러!
            this.bal.totalItems = result.totalItems
            this.bal.list = result.list
          }

          this.resolve(true)
          this.dialog = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 발령일 셀렉트 선택시 저장 함수
    saveDate1 (date) {
      this.$refs.date1Selector.save(date)
    },
    // 참고: 대상자 상세페이지 바로가기
    gotoDetail () {
      // !! 현재 경로가 /admin/viewStaffInfo 디렉토리가 아닌 경우만 /admin/viewStaffInfo 로 이동시킨다
      if (!(this.$router.history.current.path.indexOf('/admin/viewStaffInfo') !== -1)) {
        this.$router.push(`/admin/viewStaffInfo/${this.staffInfo.id}`)
        this.dialog = false
      }
    },
    // !! 카테고리 패칭 함수 - 공통함수로 빼기 난해
    async setCates (type) {
      try {
        const { data } = await this.$axios.get(`admin/cate/getType/${type}`)
        if (!data.success) throw new Error(`list error: ${data.message}`)
        return { cates: data.cates }
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
